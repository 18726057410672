import types from "./types";
export var splitItem = function splitItem(params) {
  return {
    type: types.SPLIT_ITEM,
    payload: params
  };
};
export var mergeItems = function mergeItems(params) {
  return {
    type: types.MERGE_ITEMS,
    payload: params
  };
}; // internal state

export var clearItemMergeSplitState = function clearItemMergeSplitState() {
  return {
    type: types.CLEAR_ITEM_SPLIT_STATE
  };
};
export var createSplitItems = function createSplitItems(params) {
  return {
    type: types.CREATE_SPLIT_ITEMS,
    payload: params
  };
};
export var saveItemChanges = function saveItemChanges(params) {
  return {
    type: types.SAVE_ITEM_CHANGES,
    payload: params
  };
};
export var openMergeDialog = function openMergeDialog(bool) {
  return {
    type: types.SHOW_DIALOG,
    payload: bool
  };
};