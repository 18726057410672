import _defineProperty from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _FETCH_TEMPLATE$RECEI;

// Product templates
var FETCH_TEMPLATE = "FETCH_TEMPLATE";
var RECEIVE_LIBRARY_TEMPLATES = "RECEIVE_LIBRARY_TEMPLATES";
var RECEIVE_LIBRARY_TEMPLATES_FAILED = "RECEIVE_LIBRARY_TEMPLATES_FAILED"; // Data templates

var FETCH_ALL_DATA_TEMPLATES = "FETCH_ALL_DATA_TEMPLATES";
var RECEIVE_ALL_DATA_TEMPLATES = "RECEIVE_ALL_DATA_TEMPLATES";
var RECEIVE_ALL_DATA_TEMPLATES_FAILED = "RECEIVE_ALL_DATA_TEMPLATES_FAILED";
var SAVE_TEMPLATE = "SAVE_TEMPLATE";
var RECEIVE_SAVE_TEMPLATE = "RECEIVE_SAVE_TEMPLATE";
var RECEIVE_SAVE_TEMPLATE_FAILED = "RECEIVE_SAVE_TEMPLATE_FAILED";
var GET_TEMPLATE_BY_DISPLAY_NAME = "GET_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME = "RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME_FAILED = "RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME_FAILED";
var FILTER_TEMPLATE_BY_DISPLAY_NAME = "FILTER_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME = "RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME_FAILED = "RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME_FAILED";
var DELETE_TEMPLATE_BY_DISPLAY_NAME = "DELETE_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME = "RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME";
var RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME_FAILED = "RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME_FAILED";
var CLEAR_TEMPLATE_BY_DISPLAY_NAME = "CLEAR_TEMPLATE_BY_DISPLAY_NAME";
var CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME = "CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME";
var CLEAR_STATE = "CLEAR_STATE";
var CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES"; // Upload cover image

var UPLOAD_COVER_PHOTO_TEMPLATE = "UPLOAD_COVER_PHOTO_TEMPLATE";
var RECEIVE_UPLOAD_COVER_PHOTO_TEMPLATE = "RECEIVE_UPLOAD_COVER_PHOTO_TEMPLATE";
var RECEIVE_UPLOAD_COVER_PHOTO_FAILED_TEMPLATE = "RECEIVE_UPLOAD_COVER_PHOTO_FAILED_TEMPLATE"; // Upload display image

var UPLOAD_DISPLAY_PICTURE_TEMPLATE = "UPLOAD_DISPLAY_PICTURE_TEMPLATE";
var RECEIVE_UPLOAD_DISPLAY_PICTURE_TEMPLATE = "RECEIVE_UPLOAD_DISPLAY_PICTURE_TEMPLATE";
var RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED_TEMPLATE = "RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED_TEMPLATE"; // Display name - tags - description

var SET_DISPLAY_NAME_TEMPLATE = "SET_DISPLAY_NAME_TEMPLATE";
var SET_TAGS_TEMPLATE = "SET_TAGS_TEMPLATE";
var ITEM_DESCRIPTION_TEMPLATE = "ITEM_DESCRIPTION_TEMPLATE";
var EDIT_COVER_PHOTO_TEMPLATE = "EDIT_COVER_PHOTO_TEMPLATE";
var EDIT_DISPLAY_PICTURE_TEMPLATE = "EDIT_DISPLAY_PICTURE_TEMPLATE";
export default (_FETCH_TEMPLATE$RECEI = {
  FETCH_TEMPLATE: FETCH_TEMPLATE,
  RECEIVE_LIBRARY_TEMPLATES: RECEIVE_LIBRARY_TEMPLATES,
  RECEIVE_LIBRARY_TEMPLATES_FAILED: RECEIVE_LIBRARY_TEMPLATES_FAILED,
  FETCH_ALL_DATA_TEMPLATES: FETCH_ALL_DATA_TEMPLATES,
  RECEIVE_ALL_DATA_TEMPLATES: RECEIVE_ALL_DATA_TEMPLATES,
  RECEIVE_ALL_DATA_TEMPLATES_FAILED: RECEIVE_ALL_DATA_TEMPLATES_FAILED,
  SAVE_TEMPLATE: SAVE_TEMPLATE,
  RECEIVE_SAVE_TEMPLATE: RECEIVE_SAVE_TEMPLATE,
  RECEIVE_SAVE_TEMPLATE_FAILED: RECEIVE_SAVE_TEMPLATE_FAILED,
  GET_TEMPLATE_BY_DISPLAY_NAME: GET_TEMPLATE_BY_DISPLAY_NAME,
  RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME: RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME,
  RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME_FAILED: RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME_FAILED,
  CLEAR_TEMPLATE_BY_DISPLAY_NAME: CLEAR_TEMPLATE_BY_DISPLAY_NAME,
  CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME: CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME,
  FILTER_TEMPLATE_BY_DISPLAY_NAME: FILTER_TEMPLATE_BY_DISPLAY_NAME,
  RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME: RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME,
  RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME_FAILED: RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME_FAILED
}, _defineProperty(_FETCH_TEMPLATE$RECEI, "CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME", CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME), _defineProperty(_FETCH_TEMPLATE$RECEI, "DELETE_TEMPLATE_BY_DISPLAY_NAME", DELETE_TEMPLATE_BY_DISPLAY_NAME), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME", RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME_FAILED", RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME_FAILED), _defineProperty(_FETCH_TEMPLATE$RECEI, "CLEAR_STATE", CLEAR_STATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "CLEAR_ERROR_MESSAGES", CLEAR_ERROR_MESSAGES), _defineProperty(_FETCH_TEMPLATE$RECEI, "UPLOAD_COVER_PHOTO_TEMPLATE", UPLOAD_COVER_PHOTO_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_UPLOAD_COVER_PHOTO_TEMPLATE", RECEIVE_UPLOAD_COVER_PHOTO_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_UPLOAD_COVER_PHOTO_FAILED_TEMPLATE", RECEIVE_UPLOAD_COVER_PHOTO_FAILED_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "UPLOAD_DISPLAY_PICTURE_TEMPLATE", UPLOAD_DISPLAY_PICTURE_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_UPLOAD_DISPLAY_PICTURE_TEMPLATE", RECEIVE_UPLOAD_DISPLAY_PICTURE_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED_TEMPLATE", RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "SET_DISPLAY_NAME_TEMPLATE", SET_DISPLAY_NAME_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "SET_TAGS_TEMPLATE", SET_TAGS_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "ITEM_DESCRIPTION_TEMPLATE", ITEM_DESCRIPTION_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "EDIT_COVER_PHOTO_TEMPLATE", EDIT_COVER_PHOTO_TEMPLATE), _defineProperty(_FETCH_TEMPLATE$RECEI, "EDIT_DISPLAY_PICTURE_TEMPLATE", EDIT_DISPLAY_PICTURE_TEMPLATE), _FETCH_TEMPLATE$RECEI);