import types from "./types";
export var login = function login(params) {
  return {
    type: types.LOGIN,
    payload: params
  };
};
export var receiveLogin = function receiveLogin(json) {
  return {
    type: types.RECEIVE_LOGIN,
    payload: json
  };
};
export var receiveLoginFailed = function receiveLoginFailed(err) {
  return {
    type: types.RECEIVE_LOGIN_FAILED,
    payload: err
  };
};
export var logout = function logout() {
  return {
    type: types.LOGOUT
  };
};
export var setLogoutFlag = function setLogoutFlag(bool) {
  return {
    type: types.SET_LOGOUT_FLAG,
    payload: bool
  };
};
export var createUser = function createUser(json) {
  return {
    type: types.CREATE_USER,
    payload: json
  };
};
export var selectedUbn = function selectedUbn(ubn) {
  return {
    type: types.SELECTED_UBN,
    payload: ubn
  };
};
export var setUserProfile = function setUserProfile(profile) {
  return {
    type: types.SET_USER_PROFILE,
    payload: profile
  };
};
export var setMyCompanies = function setMyCompanies(companies) {
  return {
    type: types.MY_COMPANIES,
    payload: companies
  };
};
export var setAllCompanies = function setAllCompanies(all) {
  return {
    type: types.SET_ALL_COMPANIES,
    payload: all
  };
};