import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from './types';
var INITIAL_STATE = {
  //Global Items
  globalItemsRes: [],
  globalItemsLoading: false,
  globalItemsErr: {},
  globalItemsPaginationCount: 1
};

var globalItemsReducer = function globalItemsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // Global
    case types.FETCH_INVENTORY_ITEMS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          globalItemsLoading: true
        });
      }

    case types.RECEIVE_GLOBAL_ITEMS:
      {
        var globalItemsRes = action.payload.data;
        return _objectSpread(_objectSpread({}, state), {}, {
          globalItemsRes: globalItemsRes,
          globalItemsPaginationCount: action.payload['pages'],
          globalItemsLoading: false
        });
      }

    case types.RECEIVE_GLOBAL_ITEMS_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          globalItemsErr: action.payload,
          globalItemsLoading: false
        });
      }

    case types.CLEAR_GLOBAL_ITEMS_STATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          globalItemsRes: [],
          globalItemsErr: {}
        });
      }

    case types.CLEAR_GLOBAL_ITEMS_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          globalItemsErr: {}
        });
      }

    default:
      return state;
  }
};

export default globalItemsReducer;