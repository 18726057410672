import types from "./types";
export var fetchAllDataTemplates = function fetchAllDataTemplates(json) {
  return {
    type: types.FETCH_ALL_DATA_TEMPLATES,
    payload: json
  };
};
export var fetchTemplates = function fetchTemplates(json) {
  return {
    type: types.FETCH_TEMPLATE,
    payload: json
  };
};
export var saveTemplate = function saveTemplate(json) {
  return {
    type: types.SAVE_TEMPLATE,
    payload: json
  };
};
export var getTemplateByDisplayName = function getTemplateByDisplayName(json) {
  return {
    type: types.GET_TEMPLATE_BY_DISPLAY_NAME,
    payload: json
  };
};
export var filterTemplateByDisplayName = function filterTemplateByDisplayName(json) {
  return {
    type: types.FILTER_TEMPLATE_BY_DISPLAY_NAME,
    payload: json
  };
};
export var deleteTemplateByDisplayName = function deleteTemplateByDisplayName(json) {
  return {
    type: types.DELETE_TEMPLATE_BY_DISPLAY_NAME,
    payload: json
  };
};
export var clearState = function clearState() {
  return {
    type: types.CLEAR_STATE
  };
};
export var clearErrorMessages = function clearErrorMessages() {
  return {
    type: types.CLEAR_ERROR_MESSAGES
  };
};
export var clearTemplateByDisplayName = function clearTemplateByDisplayName() {
  return {
    type: types.CLEAR_TEMPLATE_BY_DISPLAY_NAME
  };
};
export var clearFilterTemplateByDisplayName = function clearFilterTemplateByDisplayName() {
  return {
    type: types.CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME
  };
};
export var setDisplayName = function setDisplayName(displayName) {
  return {
    type: types.SET_DISPLAY_NAME_TEMPLATE,
    payload: displayName
  };
};
export var setTags = function setTags(tags) {
  return {
    type: types.SET_TAGS_TEMPLATE,
    payload: tags
  };
};
export var setItemDescription = function setItemDescription(text) {
  return {
    type: types.ITEM_DESCRIPTION_TEMPLATE,
    payload: text
  };
};
export var uploadCoverPhoto = function uploadCoverPhoto(data) {
  return {
    type: types.UPLOAD_COVER_PHOTO_TEMPLATE,
    payload: data
  };
};
export var uploadDisplayPicture = function uploadDisplayPicture(data) {
  return {
    type: types.UPLOAD_DISPLAY_PICTURE_TEMPLATE,
    payload: data
  };
};
export var editCoverPhoto = function editCoverPhoto(link) {
  return {
    type: types.EDIT_COVER_PHOTO_TEMPLATE,
    payload: link
  };
};
export var editDisplayPicture = function editDisplayPicture(link) {
  return {
    type: types.EDIT_DISPLAY_PICTURE_TEMPLATE,
    payload: link
  };
};