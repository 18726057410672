import types from "./types"; // Item Detail

export var fetchItemDetail = function fetchItemDetail(params) {
  return {
    type: types.FETCH_ITEM_DETAIL,
    payload: params
  };
};
export var fetchAndShareItemDetail = function fetchAndShareItemDetail(params) {
  return {
    type: types.FETCH_AND_SHARE,
    payload: params
  };
};
export var receiveItemDetail = function receiveItemDetail(json) {
  return {
    type: types.RECEIVE_ITEM_DETAIL,
    payload: json
  };
};
export var receiveItemDetailFailed = function receiveItemDetailFailed(err) {
  return {
    type: types.RECEIVE_ITEM_DETAIL_FAILED,
    payload: err
  };
}; //Product DNA
// Item Detail

export var fetchProductDNA = function fetchProductDNA(params) {
  return {
    type: types.FETCH_PRODUCT_DNA,
    payload: params
  };
};
export var receiveProductDNA = function receiveProductDNA(json) {
  return {
    type: types.RECEIVE_PRODUCT_DNA,
    payload: json
  };
};
export var receiveProductDNAFailed = function receiveProductDNAFailed(err) {
  return {
    type: types.RECEIVE_PRODUCT_DNA_FAILED,
    payload: err
  };
};
export var clearProductDNA = function clearProductDNA() {
  return {
    type: types.CLEAR_PRODUCT_DNA
  };
};
export var resetItem = function resetItem() {
  return {
    type: types.RESET_ITEM
  };
}; // Add Data

export var addData = function addData(params) {
  return {
    type: types.ADD_DATA,
    payload: params
  };
};
export var receiveAddData = function receiveAddData(json) {
  return {
    type: types.RECEIVE_ADD_DATA,
    payload: json
  };
};
export var receiveAddDataFailed = function receiveAddDataFailed(error) {
  return {
    type: types.RECEIVE_ADD_DATA_FAILED,
    error: error
  };
};
export var resetAddData = function resetAddData(params) {
  return {
    type: types.RESET_ADD_DATA,
    payload: params
  };
}; // Transfer item

export var transferItem = function transferItem(params) {
  return {
    type: types.TRANSFER_ITEM,
    payload: params
  };
};
export var receiveTransferItem = function receiveTransferItem(json) {
  return {
    type: types.RECEIVE_TRANSFER_ITEM,
    payload: json
  };
};
export var receiveTransferItemFailed = function receiveTransferItemFailed(err) {
  return {
    type: types.RECEIVE_TRANSFER_ITEM_FAILED,
    payload: err
  };
};
export var resetTransferItem = function resetTransferItem() {
  return {
    type: types.RESET_TRANSFER_ITEM
  };
}; // Anchor

export var anchors = function anchors(data) {
  return {
    type: types.ANCHORS,
    payload: data
  };
};
export var createAnchors = function createAnchors(data) {
  return {
    type: types.CREATE_ANCHORS,
    payload: data
  };
};
export var getAnchor = function getAnchor(data) {
  return {
    type: types.GET_ANCHOR,
    payload: data
  };
};
export var setAnchor = function setAnchor(data) {
  return {
    type: types.SET_ANCHOR,
    payload: data
  };
};
export var getItemAnchors = function getItemAnchors(data) {
  return {
    type: types.GET_ITEM_ANCHORS,
    payload: data
  };
};
export var resetAnchors = function resetAnchors(data) {
  return {
    type: types.RESET_ANCHORS,
    payload: data
  };
}; // ========== Share Item ===========

export var shareItem = function shareItem(data) {
  return {
    type: types.SHARE_ITEM,
    payload: data
  };
};
export var shareItemResponse = function shareItemResponse(data) {
  return {
    type: types.SHARE_ITEM_RESPONSE,
    payload: data
  };
};
export var shareItemFailed = function shareItemFailed(data) {
  return {
    type: types.SHARE_ITEM_FAILED,
    payload: data
  };
}; // ========= edit share item =========

export var editShareItem = function editShareItem(data) {
  return {
    type: types.EDIT_SHARE_ITEM,
    payload: data
  };
};
export var editShareItemResponse = function editShareItemResponse(data) {
  return {
    type: types.EDIT_SHARE_ITEM_RESPONSE,
    payload: data
  };
};
export var editShareItemFailed = function editShareItemFailed(data) {
  return {
    type: types.EDIT_SHARE_ITEM_FAILED,
    payload: data
  };
}; // ========== delete share item by shareId

export var deleteShareItem = function deleteShareItem(data) {
  return {
    type: types.DELETE_SHARE_ITEM,
    payload: data
  };
};
export var deleteShareItemResponse = function deleteShareItemResponse(data) {
  return {
    type: types.DELETE_SHARE_ITEM_RESPONSE,
    payload: data
  };
};
export var deleteShareItemFailed = function deleteShareItemFailed(data) {
  return {
    type: types.DELETE_SHARE_ITEM_FAILED,
    payload: data
  };
}; // ========= get sharing info for item

export var getSharingInfoForItem = function getSharingInfoForItem(itemId) {
  return {
    type: types.SHARING_INFO,
    payload: itemId
  };
};
export var getSharingInfoForItemResponse = function getSharingInfoForItemResponse(bool) {
  return {
    type: types.SHARING_INFO_RESPONSE,
    payload: bool
  };
};
export var getSharingCountByItemid = function getSharingCountByItemid(json) {
  return {
    type: types.SHARING_COUNT,
    payload: json
  };
};
export var getSharedEntries = function getSharedEntries(json) {
  return {
    type: types.SHARED_ENTRIES,
    payload: json
  };
}; // get subscriptions

export var getSubscriptions = function getSubscriptions(params) {
  return {
    type: types.GET_SUBSCRIPTIONS,
    payload: params
  };
}; // subscribe

export var subscribe = function subscribe(params) {
  return {
    type: types.SUBSCRIBE,
    payload: params
  };
}; // unsubscribe

export var unsubscribe = function unsubscribe(params) {
  return {
    type: types.UNSUBSCRIBE,
    payload: params
  };
}; // Files

export var getSendData = function getSendData(params) {
  return {
    type: types.GET_SEND_DATA,
    payload: params
  };
};
export var getPublicShareUser = function getPublicShareUser(params) {
  return {
    type: types.GET_PUBLIC_SHARE_USER,
    payload: params
  };
};
export var uploadFile = function uploadFile(params) {
  return {
    type: types.UPLOAD_FILE,
    payload: params
  };
};
export var uploadFileBase64 = function uploadFileBase64(params) {
  return {
    type: types.UPLOAD_FILE_BASE64,
    payload: params
  };
};
export var receiveUploadFile = function receiveUploadFile(params) {
  return {
    type: types.RECEIVE_UPLOAD_FILE,
    payload: params
  };
};
export var downloadPublicSharedPackage = function downloadPublicSharedPackage(params) {
  return {
    type: types.DOWNLOAD_PUBLIC_SHARED_PACKAGE,
    payload: params
  };
};
export var deleteFile = function deleteFile(params) {
  return {
    type: types.DELETE_FILE,
    payload: params
  };
};
export var deleteUploadedFile = function deleteUploadedFile(params) {
  return {
    type: types.DELETE_UPLOADED_FILE,
    payload: params
  };
};
export var getLatestJobStatus = function getLatestJobStatus(params) {
  return {
    type: types.GET_LATEST_JOB_STATUS,
    payload: params
  };
};
export var prepareFileForDownload = function prepareFileForDownload(params) {
  return {
    type: types.PREPARE_FILE_FOR_DOWNLOAD,
    payload: params
  };
};
export var downloadFile = function downloadFile(params) {
  return {
    type: types.DOWNLOAD_FILE,
    payload: params
  };
};
export var getPackageDetails = function getPackageDetails(params) {
  return {
    type: types.GET_PACKAGE_DETAILS,
    payload: params
  };
};