import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var INITIAL_STATE = {
  //Last Locations
  lastLocationsRes: [],
  lastLocationsLoading: false,
  lastLocationsErr: {},
  //First Locations
  firstLocationsRes: []
};

var locationsReducer = function locationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    //Last Locations
    case types.RECEIVE_LAST_LOCATIONS:
      {
        var lastLocationsRes = action.payload;
        var locationType = action.locationType;
        return _objectSpread(_objectSpread({}, state), {}, {
          lastLocationsRes: lastLocationsRes,
          lastLocationsLoading: false,
          locationType: locationType
        });
      }

    case types.RECEIVE_LAST_LOCATIONS_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lastLocationsLoading: false,
          lastLocationsErr: action.error
        });
      }

    case types.FETCH_LAST_LOCATIONS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lastLocationsLoading: true,
          lastLocationsErr: {}
        });
      }
    //First Locations

    case types.RECEIVE_FIRST_LOCATIONS:
      {
        var firstLocationsRes = action.payload;
        var _locationType = action.locationType;
        return _objectSpread(_objectSpread({}, state), {}, {
          firstLocationsRes: firstLocationsRes,
          lastLocationsLoading: false,
          locationType: _locationType
        });
      }

    case types.FETCH_FIRST_LOCATIONS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lastLocationsLoading: true,
          lastLocationsErr: {}
        });
      }

    case types.DELETE_ALL_LOCATIONS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lastLocationsRes: []
        });
      }

    default:
      return state;
  }
};

export default locationsReducer;