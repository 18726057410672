import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var INITIAL_STATE = {
  // Data templates
  loadingDataTemplates: false,
  dataTemplateLibrary: {},
  errorLoadingDataTemplates: {},
  // Product templates
  loadingItem: false,
  templateLibrary: {},
  errorLoadTemplate: {},
  saveTemplateResponse: {},
  errorSaveTemplate: {},
  templateByDisplayName: {},
  errorTemplateByDisplayName: {},
  filterTemplateByDisplayName: {},
  errorFilterTemplateByDisplayName: {},
  deleteTemplateResponse: {},
  errorDeleteTemplate: {},
  //create Template
  tags: [],
  displayName: "",
  itemDescription: "",
  uploadCoverPhotoLoading: false,
  coverPhotoLink: "",
  uploadDisplayPictureLoading: false,
  displayPictureLink: "",
  errorUploadImage: {},
  erroruploadDisplayPicture: {}
};

var templateLibraryReducer = function templateLibraryReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingItem: true
        });
      }

    case types.RECEIVE_LIBRARY_TEMPLATES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingItem: false,
          templateLibrary: _objectSpread(_objectSpread({}, action.payload), {}, {
            data: action.payload.data.reverse()
          })
        });
      }

    case types.RECEIVE_LIBRARY_TEMPLATES_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingItem: false,
          errorLoadTemplate: action.error
        });
      }

    case types.FETCH_ALL_DATA_TEMPLATES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingDataTemplates: true
        });
      }

    case types.RECEIVE_ALL_DATA_TEMPLATES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingDataTemplates: false,
          dataTemplateLibrary: action.payload
        });
      }

    case types.RECEIVE_ALL_DATA_TEMPLATES_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingDataTemplates: false,
          errorLoadingDataTemplates: action.error
        });
      }

    case types.RECEIVE_SAVE_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          saveTemplateResponse: action.payload
        });
      }

    case types.RECEIVE_SAVE_TEMPLATE_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorSaveTemplate: action.error
        });
      }

    case types.RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          templateByDisplayName: action.payload
        });
      }

    case types.RECEIVE_GET_TEMPLATE_BY_DISPLAY_NAME_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorTemplateByDisplayName: action.error
        });
      }

    case types.RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          filterTemplateByDisplayName: action.payload
        });
      }

    case types.RECEIVE_FILTER_TEMPLATE_BY_DISPLAY_NAME_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorFilterTemplateByDisplayName: action.error
        });
      }

    case types.RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          deleteTemplateResponse: action.payload
        });
      }

    case types.RECEIVE_DELETE_TEMPLATE_BY_DISPLAY_NAME_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorDeleteTemplate: action.error
        });
      }

    case types.SET_DISPLAY_NAME_TEMPLATE:
      {
        var displayName = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          displayName: displayName
        });
      }

    case types.SET_TAGS_TEMPLATE:
      {
        var tags = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          tags: tags
        });
      }

    case types.ITEM_DESCRIPTION_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemDescription: action.payload
        });
      }
    //Imgur link Cover Photo

    case types.UPLOAD_COVER_PHOTO_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: true
        });
      }

    case types.EDIT_COVER_PHOTO_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          coverPhotoLink: action.payload
        });
      }

    case types.RECEIVE_UPLOAD_COVER_PHOTO_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          coverPhotoLink: action.payload.rawLink
        });
      }

    case types.RECEIVE_UPLOAD_COVER_PHOTO_FAILED_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          errorUploadImage: action.error
        });
      }

    case types.CLEAR_COVER_UPLOAD_ERRORS_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorUploadImage: {}
        });
      }
    //Imgur link Avatar Photo

    case types.UPLOAD_DISPLAY_PICTURE_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: true
        });
      }

    case types.EDIT_DISPLAY_PICTURE_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          displayPictureLink: action.payload
        });
      }

    case types.RECEIVE_UPLOAD_DISPLAY_PICTURE_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          displayPictureLink: action.payload.rawLink
        });
      }

    case types.RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED_TEMPLATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          erroruploadDisplayPicture: action.error
        });
      }

    case types.CLEAR_DISPLAY_UPLOAD_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          erroruploadDisplayPicture: {}
        });
      }

    case types.CLEAR_TEMPLATE_BY_DISPLAY_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          templateByDisplayName: {}
        });
      }

    case types.CLEAR_FILTER_TEMPLATE_BY_DISPLAY_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          filterTemplateByDisplayName: {}
        });
      }

    case types.CLEAR_STATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorLoadTemplate: {},
          saveTemplateResponse: {},
          errorSaveTemplate: {},
          templateByDisplayName: {},
          errorTemplateByDisplayName: {},
          filterTemplateByDisplayName: {},
          errorFilterTemplateByDisplayName: {},
          deleteTemplateResponse: {},
          errorDeleteTemplate: {},
          tags: [],
          displayName: "",
          itemDescription: "",
          uploadCoverPhotoLoading: false,
          coverPhotoLink: "",
          uploadDisplayPictureLoading: false,
          displayPictureLink: "",
          errorUploadImage: {},
          erroruploadDisplayPicture: {}
        });
      }

    case types.CLEAR_ERROR_MESSAGES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorLoadTemplate: {},
          errorLoadingDataTemplates: {},
          errorSaveTemplate: {},
          errorTemplateByDisplayName: {},
          errorFilterTemplateByDisplayName: {},
          errorDeleteTemplate: {},
          saveTemplateResponse: {},
          deleteTemplateResponse: {}
        });
      }

    default:
      return state;
  }
};

export default templateLibraryReducer;