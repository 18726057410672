import types from "./types"; // Templates

export var setTemplate = function setTemplate(template) {
  return {
    type: types.SET_TEMPLATE,
    payload: template
  };
};
export var setTemplateData = function setTemplateData(templateData) {
  return {
    type: types.SET_TEMPLATE_DATA,
    payload: templateData
  };
};
export var resetTemplateData = function resetTemplateData() {
  return {
    type: types.RESET_TEMPLATE_DATA
  };
};
export var setDisplayName = function setDisplayName(displayName) {
  return {
    type: types.SET_DISPLAY_NAME,
    payload: displayName
  };
};
export var setTags = function setTags(tags) {
  return {
    type: types.SET_TAGS,
    payload: tags
  };
};
export var setItemDescription = function setItemDescription(text) {
  return {
    type: types.ITEM_DESCRIPTION,
    payload: text
  };
}; // Data to store

export var addTempData = function addTempData(data) {
  return {
    type: types.ADD_TEMP_DATA,
    payload: data
  };
};
export var deleteTempData = function deleteTempData(data) {
  return {
    type: types.DELETE_TEMP_DATA,
    deleteItem: data
  };
};
export var changeSfcData = function changeSfcData(key, value) {
  return {
    type: types.CHANGE_SFC_DATA,
    payload: {
      key: key,
      value: value
    }
  };
};
export var removeSfcData = function removeSfcData(key) {
  return {
    type: types.REMOVE_SFC_DATA,
    payload: key
  };
}; // Create new item

export var createItem = function createItem(data) {
  return {
    type: types.CREATE_ITEM,
    payload: data
  };
}; // Add data

export var createItemAddData = function createItemAddData(_ref) {
  var tempData = _ref.tempData,
      itemId = _ref.itemId;
  return {
    type: types.ADD_ONE_DATA,
    payload: {
      tempData: tempData,
      itemId: itemId
    }
  };
}; // Reset steps

export var resetSteps = function resetSteps() {
  return {
    type: types.RESET_STEPS
  };
}; // Load data after item inserted 

export var loadDataSequenced = function loadDataSequenced() {
  return {
    type: types.RECEIVE_DATA_FROM_CREATED_DATA
  };
};
export var uploadCoverPhoto = function uploadCoverPhoto(data) {
  return {
    type: types.UPLOAD_COVER_PHOTO,
    payload: data
  };
};
export var uploadDisplayPicture = function uploadDisplayPicture(data) {
  return {
    type: types.UPLOAD_DISPLAY_PICTURE,
    payload: data
  };
};
export var editCoverPhoto = function editCoverPhoto(link) {
  return {
    type: types.EDIT_COVER_PHOTO,
    payload: link
  };
};
export var clearCoverUploadErrors = function clearCoverUploadErrors() {
  return {
    type: types.CLEAR_COVER_UPLOAD_ERRORS
  };
};
export var editDisplayPicture = function editDisplayPicture(link) {
  return {
    type: types.EDIT_DISPLAY_PICTURE,
    payload: link
  };
};
export var clearDisplayUploadErrors = function clearDisplayUploadErrors() {
  return {
    type: types.CLEAR_DISPLAY_UPLOAD_ERRORS
  };
};
export var setAvatar = function setAvatar(link) {
  return {
    type: types.EDIT_DISPLAY_PICTURE,
    payload: link
  };
};
export var setCoverPhoto = function setCoverPhoto(link) {
  return {
    type: types.EDIT_COVER_PHOTO,
    payload: link
  };
}; //ASFIS

export var fetchAsfis = function fetchAsfis(params) {
  return {
    type: types.FETCH_ASFIS,
    payload: params
  };
};