import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from './types';
var INITIAL_STATE = {
  incomingMessagesData: [],
  outgoingMessagesData: [],
  loadingIncomingMessages: false,
  loadingOutgoingMessages: false,
  totalIncomingPages: 1,
  totalOutgoingPages: 1,
  errorMessage: {},
  errorOutgoingMessage: {}
};

var messagesReducer = function messagesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_MESSAGES:
      {
        if (action.payload['filter[isIncoming]']) {
          return _objectSpread(_objectSpread({}, state), {}, {
            loadingIncomingMessages: true
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            loadingOutgoingMessages: true
          });
        }
      }

    case types.RECEIVE_INCOMING_MESSAGES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          incomingMessagesData: action.payload.data,
          totalIncomingPages: action.payload["pages"],
          loadingIncomingMessages: false
        });
      }

    case types.RECEIVE_OUTGOING_MESSAGES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          outgoingMessagesData: action.payload.data,
          totalOutgoingPages: action.payload["pages"],
          loadingOutgoingMessages: false
        });
      }

    case types.RECEIVE_INCOMING_MESSAGES_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorMessage: action.error,
          loadingIncomingMessages: false
        });
      }

    case types.RECEIVE_OUTGOING_MESSAGES_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorOutgoingMessage: action.error,
          loadingOutgoingMessages: false
        });
      }

    default:
      return state;
  }
};

export default messagesReducer;