import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var initialState = {
  services: {},
  servicesLoading: false,
  servicesError: {}
};

var permissionsReducer = function permissionsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_SERVICES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          servicesLoading: true,
          servicesError: {}
        });
      }

    case types.RECEIVED_SERVICES_SUCCESS:
      {
        var services = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          services: services,
          servicesLoading: false
        });
      }

    case types.RECEIVED_SERVICES_ERROR:
      {
        var servicesError = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          servicesError: servicesError,
          servicesLoading: false
        });
      }

    default:
      return state;
  }
};

export default permissionsReducer;