var LOGIN = "LOGIN";
var RECEIVE_LOGIN = "RECEIVE_LOGIN";
var RECEIVE_LOGIN_FAILED = "RECEIVE_LOGIN_FAILED";
var LOGOUT = "LOGOUT";
var SET_LOGOUT_FLAG = "SET_LOGOUT_FLAG";
var CREATE_USER = "CREATE_USER";
var RECEIVE_CREATE_USER = "RECEIVE_CREATE_USER";
var RECEIVE_CREATE_USER_FAILED = "RECEIVE_CREATE_USER_FAILED";
var SELECTED_UBN = "SELECTED_UBN";
var SET_USER_PROFILE = "SET_USER_PROFILE";
var MY_COMPANIES = "MY_COMPANIES";
var SET_ALL_COMPANIES = "SET_ALL_COMPANIES";
export default {
  LOGIN: LOGIN,
  RECEIVE_LOGIN: RECEIVE_LOGIN,
  RECEIVE_LOGIN_FAILED: RECEIVE_LOGIN_FAILED,
  LOGOUT: LOGOUT,
  SET_LOGOUT_FLAG: SET_LOGOUT_FLAG,
  CREATE_USER: CREATE_USER,
  RECEIVE_CREATE_USER: RECEIVE_CREATE_USER,
  RECEIVE_CREATE_USER_FAILED: RECEIVE_CREATE_USER_FAILED,
  SELECTED_UBN: SELECTED_UBN,
  SET_USER_PROFILE: SET_USER_PROFILE,
  MY_COMPANIES: MY_COMPANIES,
  SET_ALL_COMPANIES: SET_ALL_COMPANIES
};