import types from './types';
export var fetchLastLocations = function fetchLastLocations(params) {
  return {
    type: types.FETCH_LAST_LOCATIONS,
    payload: params
  };
};
export var fetchFirstLocations = function fetchFirstLocations(params) {
  return {
    type: types.FETCH_FIRST_LOCATIONS,
    payload: params
  };
};
export var deleteAllLocations = function deleteAllLocations() {
  return {
    type: types.DELETE_ALL_LOCATIONS
  };
};