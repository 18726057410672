import _createForOfIteratorHelper from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from './types';
var INITIAL_STATE = {
  userProfileRes: undefined,
  userProfileLoading: false,
  userProfileErr: undefined,
  userSubscriptions: [],
  pricingPlans: [],
  activePricingPlans: [],
  inactivePlans: [],
  cancelSubscriptionResponse: {},
  requestSubscription: {},
  errorCancelSubscription: {},
  errorRequestSubscription: {},
  twoFASecret: {},
  enable2Fa: {},
  disable2Fa: {},
  userBilling: [],
  userProfileUpdatedFlag: false,
  showWelcomeMessage: true,
  icons: {},
  imagePreviewUrl: "/media/svg/avatars/001-boy.svg",
  imagePreviewUrlError: undefined
};

var accountManagementReducer = function accountManagementReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.USER_PROFILE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userProfileRes: undefined,
          userProfileLoading: true,
          userProfileErr: undefined
        });
      }

    case types.RECEIVE_USER_PROFILE:
      {
        var userProfile = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          userProfileRes: userProfile,
          userProfileLoading: false
        });
      }

    case types.RECEIVE_USER_PROFILE_FAILED:
      {
        var userProfileErr = action.error;
        return _objectSpread(_objectSpread({}, state), {}, {
          userProfileLoading: false,
          userProfileErr: userProfileErr
        });
      }

    case types.RECEIVE_USER_SUBSCRIPTIONS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userSubscriptions: action.payload,
          requestSubscription: {}
        });
      }

    case types.RECEIVE_PRICING_PLANS:
      {
        var pricingPlans = action.payload;
        var activePricingPlans = pricingPlans.filter(function (pricingPlan) {
          var activeSubscription = state.userSubscriptions.find(function (s) {
            return s.pricingPlanName === pricingPlan.name;
          });

          if (activeSubscription) {
            pricingPlan.activationDate = activeSubscription.activationDate;
            pricingPlan.serviceId = activeSubscription.serviceId;
            pricingPlan.subscriptionId = activeSubscription._id;
            pricingPlan.userId = activeSubscription.user;
            pricingPlan.status = activeSubscription.status;
            return true;
          }

          return false;
        }).sort(function (first, second) {
          return second.subscriptions.length - first.subscriptions.length;
        });
        var inPlans = pricingPlans.filter(function (pricingPlan) {
          return !activePricingPlans.find(function (activePlan) {
            return activePlan._id === pricingPlan._id;
          });
        });
        var inactivePlans = inPlans.map(function (plan) {
          if (plan.name.startsWith("All")) {
            return plan;
          }

          var found = [];

          var _iterator = _createForOfIteratorHelper(plan.subscriptions),
              _step;

          try {
            var _loop = function _loop() {
              var subscription = _step.value;
              var subs = state.userSubscriptions.find(function (s) {
                return s.type === subscription.type && s.plan === subscription.plan;
              });

              if (subs !== undefined) {
                found.push(subs);
              }
            };

            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              _loop();
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          return _objectSpread(_objectSpread({}, plan), {}, {
            activeInPlan: found.length > 0 ? found[0].pricingPlanName : ""
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          pricingPlans: pricingPlans,
          activePricingPlans: activePricingPlans,
          inactivePlans: inactivePlans
        });
      }

    case types.RECEIVE_CANCEL_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cancelSubscriptionResponse: action.payload
        });
      }

    case types.RECEIVE_CANCEL_SUBSCRIPTION_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorCancelSubscription: action.error
        });
      }

    case types.REQUEST_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorRequestSubscription: {}
        });
      }

    case types.RECEIVE_REQUEST_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          requestSubscription: action.payload
        });
      }

    case types.RECEIVE_REQUEST_SUBSCRIPTION_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorRequestSubscription: action.error
        });
      }

    case types.RECEIVE_GENERATE_SECRET:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          twoFASecret: action.payload
        });
      }

    case types.RECEIVE_ENABLE_2FA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          enable2Fa: action.payload
        });
      }

    case types.RECEIVE_DISABLE_2FA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          disable2Fa: action.payload
        });
      }

    case types.RECEIVE_GET_USER_BILLING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userBilling: action.payload
        });
      }

    case types.CLEAR_REQUEST_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          requestSubscription: {}
        });
      }

    case types.CLEAR_CANCEL_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cancelSubscriptionResponse: {}
        });
      }

    case types.CLEAR_ERROR_REQUEST_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorRequestSubscription: {}
        });
      }

    case types.CLEAR_ERROR_CANCEL_SUBSCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorCancelSubscription: {}
        });
      }

    case types.USER_PROFILE_UPDATED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userProfileUpdatedFlag: action.payload
        });
      }

    case types.SHOW_WELCOME_MESSAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          showWelcomeMessage: action.payload
        });
      }

    case types.RECEIVE_ICONS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          icons: action.payload
        });
      }

    case types.READ_USER_DOCUMENT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          imagePreviewUrlError: undefined
        });
      }

    case types.RECEIVE_READ_USER_DOCUMENT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          imagePreviewUrl: "data:image/png;base64, ".concat(action.payload.contents)
        });
      }

    case types.RECEIVE_READ_USER_DOCUMENT_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          imagePreviewUrlError: action.error
        });
      }

    case types.SET_DEFAULT_IMAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          imagePreviewUrl: "/media/svg/avatars/001-boy.svg"
        });
      }

    default:
      return state;
  }
};

export default accountManagementReducer;