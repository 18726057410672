import types from './types';
export var getUserProfile = function getUserProfile() {
  return {
    type: types.USER_PROFILE
  };
};
export var getUserSubscriptions = function getUserSubscriptions() {
  return {
    type: types.GET_USER_SUBSCRIPTIONS
  };
};
export var getPricingPlans = function getPricingPlans() {
  return {
    type: types.GET_PRICING_PLANS
  };
};
export var cancelSubscription = function cancelSubscription(json) {
  return {
    type: types.CANCEL_SUBSCRIPTION,
    payload: json
  };
};
export var requestSubscription = function requestSubscription(json) {
  return {
    type: types.REQUEST_SUBSCRIPTION,
    payload: json
  };
};
export var generateSecret = function generateSecret(json) {
  return {
    type: types.GENERATE_SECRET,
    payload: json
  };
};
export var enable2Fa = function enable2Fa(json) {
  return {
    type: types.ENABLE_2FA,
    payload: json
  };
};
export var disable2Fa = function disable2Fa(json) {
  return {
    type: types.DISABLE_2FA,
    payload: json
  };
};
export var getUserBilling = function getUserBilling(json) {
  return {
    type: types.GET_USER_BILLING,
    payload: json
  };
}; // clear

export var clearCancelSubscriptionResponse = function clearCancelSubscriptionResponse() {
  return {
    type: types.CLEAR_CANCEL_SUBSCRIPTION
  };
};
export var clearRequestSubscription = function clearRequestSubscription() {
  return {
    type: types.CLEAR_REQUEST_SUBSCRIPTION
  };
};
export var clearErrorRequestSubscription = function clearErrorRequestSubscription() {
  return {
    type: types.CLEAR_ERROR_REQUEST_SUBSCRIPTION
  };
};
export var clearErrorCancelSubscription = function clearErrorCancelSubscription() {
  return {
    type: types.CLEAR_ERROR_CANCEL_SUBSCRIPTION
  };
};
export var userProfileUpdated = function userProfileUpdated(bool) {
  return {
    type: types.USER_PROFILE_UPDATED,
    payload: bool
  };
};
export var showWelcomeMessage = function showWelcomeMessage(bool) {
  return {
    type: types.SHOW_WELCOME_MESSAGE,
    payload: bool
  };
};
export var getIcons = function getIcons(params) {
  return {
    type: types.GET_ICONS,
    payload: params
  };
};
export var readUserDocument = function readUserDocument(params) {
  return {
    type: types.READ_USER_DOCUMENT,
    payload: params
  };
};
export var setDefaultImage = function setDefaultImage() {
  return {
    type: types.SET_DEFAULT_IMAGE
  };
};