var USER_PROFILE = 'USER_PROFILE';
var RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE';
var RECEIVE_USER_PROFILE_FAILED = 'RECEIVE_USER_PROFILE_FAILED';
var GET_USER_SUBSCRIPTIONS = 'GET_USER_SUBSCRIPTIONS';
var RECEIVE_USER_SUBSCRIPTIONS = 'RECEIVE_USER_SUBSCRIPTIONS';
var RECEIVE_USER_SUBSCRIPTIONS_FAILED = 'RECEIVE_USER_SUBSCRIPTIONS_FAILED';
var GET_PRICING_PLANS = 'GET_PRICING_PLANS';
var RECEIVE_PRICING_PLANS = 'RECEIVE_PRICING_PLANS';
var RECEIVE_PRICING_PLANS_FAILED = 'RECEIVE_PRICING_PLANS_FAILED';
var CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
var RECEIVE_CANCEL_SUBSCRIPTION = 'RECEIVE_CANCEL_SUBSCRIPTION';
var RECEIVE_CANCEL_SUBSCRIPTION_FAILED = 'RECEIVE_CANCEL_SUBSCRIPTION_FAILED';
var REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION';
var RECEIVE_REQUEST_SUBSCRIPTION = 'RECEIVE_REQUEST_SUBSCRIPTION';
var RECEIVE_REQUEST_SUBSCRIPTION_FAILED = 'RECEIVE_REQUEST_SUBSCRIPTION_FAILED';
var GENERATE_SECRET = 'GENERATE_SECRET';
var RECEIVE_GENERATE_SECRET = 'RECEIVE_GENERATE_SECRET';
var RECEIVE_GENERATE_SECRET_FAILED = 'RECEIVE_GENERATE_SECRET_FAILED';
var ENABLE_2FA = 'ENABLE_2FA';
var RECEIVE_ENABLE_2FA = 'RECEIVE_ENABLE_2FA';
var RECEIVE_ENABLE_2FA_FAILED = 'RECEIVE_ENABLE_2FA_FAILED';
var DISABLE_2FA = 'DISABLE_2FA';
var RECEIVE_DISABLE_2FA = 'RECEIVE_DISABLE_2FA';
var RECEIVE_DISABLE_2FA_FAILED = 'RECEIVE_DISABLE_2FA_FAILED';
var GET_USER_BILLING = 'GET_USER_BILLING';
var RECEIVE_GET_USER_BILLING = 'RECEIVE_GET_USER_BILLING';
var RECEIVE_GET_USER_BILLING_FAILED = 'RECEIVE_GET_USER_BILLING_FAILED';
var CLEAR_CANCEL_SUBSCRIPTION = 'CLEAR_CANCEL_SUBSCRIPTION';
var CLEAR_REQUEST_SUBSCRIPTION = 'CLEAR_REQUEST_SUBSCRIPTION';
var CLEAR_ERROR_REQUEST_SUBSCRIPTION = 'CLEAR_ERROR_REQUEST_SUBSCRIPTION';
var CLEAR_ERROR_CANCEL_SUBSCRIPTION = 'CLEAR_ERROR_CANCEL_SUBSCRIPTION';
var USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
var SHOW_WELCOME_MESSAGE = 'SHOW_WELCOME_MESSAGE';
var GET_ICONS = 'GET_ICONS';
var RECEIVE_ICONS = 'RECEIVE_ICONS';
var RECEIVE_ICONS_FAILED = 'RECEIVE_ICONS_FAILED';
var READ_USER_DOCUMENT = 'READ_USER_DOCUMENT';
var RECEIVE_READ_USER_DOCUMENT = 'RECEIVE_READ_USER_DOCUMENT';
var RECEIVE_READ_USER_DOCUMENT_FAILED = 'RECEIVE_READ_USER_DOCUMENT_FAILED';
var SET_DEFAULT_IMAGE = 'SET_DEFAULT_IMAGE';
export default {
  USER_PROFILE: USER_PROFILE,
  RECEIVE_USER_PROFILE: RECEIVE_USER_PROFILE,
  RECEIVE_USER_PROFILE_FAILED: RECEIVE_USER_PROFILE_FAILED,
  GET_USER_SUBSCRIPTIONS: GET_USER_SUBSCRIPTIONS,
  RECEIVE_USER_SUBSCRIPTIONS: RECEIVE_USER_SUBSCRIPTIONS,
  RECEIVE_USER_SUBSCRIPTIONS_FAILED: RECEIVE_USER_SUBSCRIPTIONS_FAILED,
  GET_PRICING_PLANS: GET_PRICING_PLANS,
  RECEIVE_PRICING_PLANS: RECEIVE_PRICING_PLANS,
  RECEIVE_PRICING_PLANS_FAILED: RECEIVE_PRICING_PLANS_FAILED,
  CANCEL_SUBSCRIPTION: CANCEL_SUBSCRIPTION,
  RECEIVE_CANCEL_SUBSCRIPTION: RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CANCEL_SUBSCRIPTION_FAILED: RECEIVE_CANCEL_SUBSCRIPTION_FAILED,
  REQUEST_SUBSCRIPTION: REQUEST_SUBSCRIPTION,
  RECEIVE_REQUEST_SUBSCRIPTION: RECEIVE_REQUEST_SUBSCRIPTION,
  RECEIVE_REQUEST_SUBSCRIPTION_FAILED: RECEIVE_REQUEST_SUBSCRIPTION_FAILED,
  GENERATE_SECRET: GENERATE_SECRET,
  RECEIVE_GENERATE_SECRET: RECEIVE_GENERATE_SECRET,
  RECEIVE_GENERATE_SECRET_FAILED: RECEIVE_GENERATE_SECRET_FAILED,
  ENABLE_2FA: ENABLE_2FA,
  RECEIVE_ENABLE_2FA: RECEIVE_ENABLE_2FA,
  RECEIVE_ENABLE_2FA_FAILED: RECEIVE_ENABLE_2FA_FAILED,
  DISABLE_2FA: DISABLE_2FA,
  RECEIVE_DISABLE_2FA: RECEIVE_DISABLE_2FA,
  RECEIVE_DISABLE_2FA_FAILED: RECEIVE_DISABLE_2FA_FAILED,
  GET_USER_BILLING: GET_USER_BILLING,
  RECEIVE_GET_USER_BILLING: RECEIVE_GET_USER_BILLING,
  RECEIVE_GET_USER_BILLING_FAILED: RECEIVE_GET_USER_BILLING_FAILED,
  CLEAR_CANCEL_SUBSCRIPTION: CLEAR_CANCEL_SUBSCRIPTION,
  CLEAR_REQUEST_SUBSCRIPTION: CLEAR_REQUEST_SUBSCRIPTION,
  CLEAR_ERROR_REQUEST_SUBSCRIPTION: CLEAR_ERROR_REQUEST_SUBSCRIPTION,
  CLEAR_ERROR_CANCEL_SUBSCRIPTION: CLEAR_ERROR_CANCEL_SUBSCRIPTION,
  USER_PROFILE_UPDATED: USER_PROFILE_UPDATED,
  SHOW_WELCOME_MESSAGE: SHOW_WELCOME_MESSAGE,
  GET_ICONS: GET_ICONS,
  RECEIVE_ICONS: RECEIVE_ICONS,
  RECEIVE_ICONS_FAILED: RECEIVE_ICONS_FAILED,
  READ_USER_DOCUMENT: READ_USER_DOCUMENT,
  RECEIVE_READ_USER_DOCUMENT: RECEIVE_READ_USER_DOCUMENT,
  RECEIVE_READ_USER_DOCUMENT_FAILED: RECEIVE_READ_USER_DOCUMENT_FAILED,
  SET_DEFAULT_IMAGE: SET_DEFAULT_IMAGE
};