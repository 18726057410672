import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var INITIAL_STATE = {
  statsRes: {},
  statsLoading: false,
  statsError: {},
  itemsExtendedStatsRes: {},
  itemsExtendedStatsLoading: false,
  itemExtendedStatsError: {},
  eventsWeekExtendedStatsRes: {},
  eventsWeekExtendedStatsError: {},
  eventsMonthExtendedStatsRes: {},
  eventsMonthExtendedStatsError: {},
  bdiExtendedStatsRes: {},
  bdiExtendedStatsError: {},
  eventLogResponse: {},
  eventLogLoading: false,
  eventLogError: {}
};

var statisticsReducer = function statisticsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_STATISTICS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          statsLoading: true,
          statsError: {}
        });
      }

    case types.RECEIVE_STATISTICS:
      {
        var statsRes = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          statsRes: statsRes,
          statsLoading: false
        });
      }

    case types.RECEIVE_STATISTICS_FAILED:
      {
        var statsError = action.error;
        return _objectSpread(_objectSpread({}, state), {}, {
          statsError: statsError,
          statsLoading: false
        });
      }
    // Extended Statistics

    case types.FETCH_EXTENDED_STATISTICS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemsExtendedStatsLoading: true,
          itemsExtendedStatsRes: {}
        });
      }

    case types.RECEIVE_ITEMS_EXTENDED_STATISTICS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemsExtendedStatsRes: action.payload,
          itemsExtendedStatsLoading: false
        });
      }

    case types.RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemExtendedStatsError: action.error,
          itemsExtendedStatsLoading: false
        });
      }
    // events week

    case types.RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventsWeekExtendedStatsRes: action.payload
        });
      }

    case types.RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventsWeekExtendedStatsError: action.error
        });
      }
    // events month

    case types.RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventsMonthExtendedStatsRes: action.payload
        });
      }

    case types.RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventsMonthExtendedStatsError: action.error
        });
      }
    // bdi

    case types.RECEIVE_BDI_EXTENDED_STATISTICS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          bdiExtendedStatsRes: action.payload
        });
      }

    case types.RECEIVE_BDI_EXTENDED_STATISTICS_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          bdiExtendedStatsError: action.error
        });
      }
    // Event log

    case types.FETCH_EVENT_LOG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventLogLoading: true,
          eventLogError: {}
        });
      }

    case types.RECEIVE_EVENT_LOG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventLogResponse: action.payload,
          eventLogLoading: false
        });
      }

    case types.RECEIVE_EVENT_LOG_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          eventLogError: action.error,
          eventLogLoading: false
        });
      }

    case types.RESET_STATE:
      {
        return INITIAL_STATE;
      }

    default:
      return state;
  }
};

export default statisticsReducer;