import _toConsumableArray from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var INITIAL_STATE = {
  template: 'unisot',
  templateData: [],
  displayName: '',
  tags: [],
  itemDescription: '',
  // Temp Data
  tempData: [],
  // CreateItem
  createItemResponse: undefined,
  createItemLoading: false,
  createItemError: undefined,
  // Add data
  addDataRes: undefined,
  addDataLoading: false,
  addDataErr: undefined,
  //Pic link
  uploadCoverPhotoLoading: false,
  coverPhotoLink: '',
  uploadDisplayPictureLoading: false,
  displayPictureLink: '',
  errorUploadImage: {},
  erroruploadDisplayPicture: {},
  //asfis
  asfisLoading: false,
  asfisData: [],
  errorAsfis: {}
};

var itemCreateReducer = function itemCreateReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // Custom DT
    case types.SET_TEMPLATE:
      {
        var template = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          template: template
        });
      }

    case types.SET_TEMPLATE_DATA:
      {
        var templateData = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          templateData: templateData
        });
      }

    case types.RESET_TEMPLATE_DATA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          templateData: []
        });
      }

    case types.SET_DISPLAY_NAME:
      {
        var displayName = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          displayName: displayName
        });
      }

    case types.SET_TAGS:
      {
        var tags = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          tags: tags
        });
      }

    case types.ITEM_DESCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemDescription: action.payload
        });
      }
    // Add Temp Data

    case types.ADD_TEMP_DATA:
      {
        var temp = _toConsumableArray(state.tempData);

        temp.unshift(action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          tempData: temp
        });
      }

    case types.DELETE_TEMP_DATA:
      {
        var tempData = _toConsumableArray(state.tempData);

        var deleteIndex = tempData.indexOf(action.deleteItem);

        if (deleteIndex > -1) {
          tempData.splice(deleteIndex, 1);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          tempData: tempData
        });
      }

    case types.CHANGE_SFC_DATA:
      {
        var key = action.payload.key;
        var value = action.payload.value;

        var _temp = _toConsumableArray(state.tempData);

        var objIndex = state.tempData.findIndex(function (obj) {
          return obj.payload[0].key == key;
        });
        _temp[objIndex].payload[0].value = value;
        return _objectSpread(_objectSpread({}, state), {}, {
          tempData: _temp
        });
      }

    case types.REMOVE_SFC_DATA:
      {
        var _key = action.payload;

        var _temp2 = _toConsumableArray(state.tempData);

        var _deleteIndex = _temp2.map(function (item) {
          return item.payload[0].key;
        }).indexOf(_key);

        if (_deleteIndex > -1) {
          _temp2.splice(_deleteIndex, 1);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          tempData: _temp2
        });
      }
    // ADD DATA

    case types.ADD_ONE_DATA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          addDataLoading: true,
          addDataRes: {},
          addDataErr: {}
        });
      }

    case types.RECEIVE_ADD_ONE_DATA:
      {
        var addDataRes = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          addDataRes: addDataRes,
          addDataLoading: false,
          addDataErr: {}
        });
      }

    case types.RECEIVE_ADD_ONE_DATA_FAILED:
      {
        var addDataErr = action.error;
        return _objectSpread(_objectSpread({}, state), {}, {
          addDataErr: addDataErr,
          addDataLoading: false
        });
      }
    // Create Item

    case types.CREATE_ITEM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          createItemLoading: true,
          createItemError: {},
          addDataRes: {},
          addDataErr: {}
        });
      }

    case types.RECEIVE_CREATE_ITEM:
      {
        var createItemResponse = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          createItemResponse: createItemResponse,
          createItemLoading: false
        });
      }

    case types.RECEIVE_CREATE_ITEM_FAILED:
      {
        var createItemError = action.error;
        return _objectSpread(_objectSpread({}, state), {}, {
          createItemError: createItemError,
          createItemLoading: false
        });
      }
    // Reset steps

    case types.RESET_STEPS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          tempData: [],
          templateData: [],
          template: 'unisot',
          displayName: '',
          tags: [],
          createItemResponse: {},
          createItemError: {},
          addDataRes: {},
          addDataErr: {},
          coverPhotoLink: '',
          displayPictureLink: '',
          itemDescription: ''
        });
      }
    //Imgur link Cover Photo

    case types.UPLOAD_COVER_PHOTO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: true
        });
      }

    case types.RECEIVE_UPLOAD_COVER_PHOTO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          coverPhotoLink: action.payload.rawLink
        });
      }

    case types.EDIT_COVER_PHOTO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          coverPhotoLink: action.payload
        });
      }

    case types.RECEIVE_UPLOAD_COVER_PHOTO_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadCoverPhotoLoading: false,
          errorUploadImage: action.error
        });
      }

    case types.CLEAR_COVER_UPLOAD_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorUploadImage: {}
        });
      }
    //Imgur link Avatar Photo

    case types.UPLOAD_DISPLAY_PICTURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: true
        });
      }

    case types.RECEIVE_UPLOAD_DISPLAY_PICTURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          displayPictureLink: action.payload.rawLink
        });
      }

    case types.EDIT_DISPLAY_PICTURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          displayPictureLink: action.payload
        });
      }

    case types.RECEIVE_UPLOAD_DISPLAY_PICTURE_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          uploadDisplayPictureLoading: false,
          erroruploadDisplayPicture: action.error
        });
      }

    case types.CLEAR_DISPLAY_UPLOAD_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          erroruploadDisplayPicture: {}
        });
      }

    case types.FETCH_ASFIS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          asfisLoading: true
        });
      }

    case types.RECEIVE_ASFIS_DATA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          asfisLoading: false,
          asfisData: action.payload
        });
      }

    case types.RECEIVE_ASFIS_DATA_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          asfisLoading: false,
          errorAsfis: action.error
        });
      }

    default:
      return state;
  }
};

export default itemCreateReducer;