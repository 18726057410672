import types from './types'; //========== bdi-messages Actions =============

export var fetchMessages = function fetchMessages(json) {
  return {
    type: types.FETCH_MESSAGES,
    payload: json
  };
};
export var receiveBDIMessages = function receiveBDIMessages(json) {
  if (json.isIncoming) {
    return {
      type: types.RECEIVE_INCOMING_MESSAGES,
      payload: json.result
    };
  } else {
    return {
      type: types.RECEIVE_OUTGOING_MESSAGES,
      payload: json.result
    };
  }
};
export var receiveBDIMessagesFailed = function receiveBDIMessagesFailed(json) {
  if (json.isIncoming) {
    return {
      type: types.RECEIVE_INCOMING_MESSAGES_FAILED,
      payload: json.error
    };
  } else {
    return {
      type: types.RECEIVE_OUTGOING_MESSAGES_FAILED,
      payload: json.error
    };
  }
};