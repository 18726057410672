var FETCH_MESSAGES = 'FETCH_MESSAGES';
var RECEIVE_MESSAGES_DATA = 'RECEIVE_MESSAGES_DATA';
var RECEIVE_MESSAGES_DATA_FAILED = 'RECEIVE_MESSAGES_DATA_FAILED';
var RECEIVE_INCOMING_MESSAGES = 'RECEIVE_INCOMING_MESSAGES';
var RECEIVE_INCOMING_MESSAGES_FAILED = 'RECEIVE_INCOMING_MESSAGES_FAILED';
var RECEIVE_OUTGOING_MESSAGES = 'RECEIVE_OUTGOING_MESSAGES';
var RECEIVE_OUTGOING_MESSAGES_FAILED = 'RECEIVE_OUTGOING_MESSAGES_FAILED';
export default {
  FETCH_MESSAGES: FETCH_MESSAGES,
  RECEIVE_MESSAGES_DATA: RECEIVE_MESSAGES_DATA,
  RECEIVE_MESSAGES_DATA_FAILED: RECEIVE_MESSAGES_DATA_FAILED,
  RECEIVE_INCOMING_MESSAGES: RECEIVE_INCOMING_MESSAGES,
  RECEIVE_INCOMING_MESSAGES_FAILED: RECEIVE_INCOMING_MESSAGES_FAILED,
  RECEIVE_OUTGOING_MESSAGES: RECEIVE_OUTGOING_MESSAGES,
  RECEIVE_OUTGOING_MESSAGES_FAILED: RECEIVE_OUTGOING_MESSAGES_FAILED
};