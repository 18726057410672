import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import types from "./types";
var initialState = {
  loginRes: {},
  loginLoading: false,
  loginErr: {},
  logoutFlag: false,
  createUserRes: null,
  createUserLoading: false,
  createUserErr: null,
  selectedUbn: "",
  userProfile: {},
  myCompanies: {},
  allCompanies: {}
};

var loginReducer = function loginReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.LOGIN:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loginRes: {},
          loginLoading: true,
          loginErr: {}
        });
      }

    case types.RECEIVE_LOGIN:
      {
        var loginRes = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loginRes: loginRes,
          loginLoading: false
        });
      }

    case types.RECEIVE_LOGIN_FAILED:
      {
        var loginErr = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loginErr: loginErr,
          loginLoading: false
        });
      }

    case types.LOGOUT:
      {
        return initialState;
      }

    case types.SET_LOGOUT_FLAG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          logoutFlag: action.payload
        });
      }

    case types.CREATE_USER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          createUserRes: null,
          createUserLoading: true,
          createUserErr: null
        });
      }

    case types.RECEIVE_CREATE_USER:
      {
        var createUserRes = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          createUserRes: createUserRes,
          createUserLoading: false
        });
      }

    case types.RECEIVE_CREATE_USER_FAILED:
      {
        var createUserErr = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          createUserErr: createUserErr,
          createUserLoading: false
        });
      }

    case types.SELECTED_UBN:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedUbn: action.payload
        });
      }

    case types.SET_USER_PROFILE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userProfile: action.payload
        });
      }

    case types.MY_COMPANIES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          myCompanies: action.payload
        });
      }

    case types.SET_ALL_COMPANIES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allCompanies: action.payload
        });
      }

    default:
      return state;
  }
};

export default loginReducer;