import Moment from 'moment';
export var isObjectEmpty = function isObjectEmpty(obj) {
  if (!obj) return true;
  return obj && Object.entries(obj).length === 0 && obj.constructor === Object;
};
export var dateString = "dd.MM.yyyy";
export var dateTimeString = "dd.MM.yyyy HH:mm";
export var formatDate = function formatDate(date) {
  return Moment(date).format("DD.MM.YYYY");
};
export var formatDateTime = function formatDateTime(dateTime) {
  var endLine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (endLine) {
    return Moment(dateTime).format("DD.MM.YYYY [\n]HH:mm");
  } else {
    return Moment(dateTime).format("DD.MM.YYYY HH:mm");
  }
};
export var pinColor = function pinColor(inputString) {
  // OPTIONS
  // 1. set hardcoded color for each endpoint
  // 2. colorHash based on creator
  var sum = 0;

  for (var i in inputString) {
    sum += inputString.charCodeAt(i);
  }

  r = ~~(('0.' + Math.sin(sum + 1).toString().substr(6)) * 256);
  g = ~~(('0.' + Math.sin(sum + 2).toString().substr(6)) * 256);
  b = ~~(('0.' + Math.sin(sum + 3).toString().substr(6)) * 256);
  var rgb = "rgb(" + r + ", " + g + ", " + b + ")";
  var hex = "#";
  hex += ("00" + r.toString(16)).substr(-2, 2).toUpperCase();
  hex += ("00" + g.toString(16)).substr(-2, 2).toUpperCase();
  hex += ("00" + b.toString(16)).substr(-2, 2).toUpperCase();
  return {
    r: r,
    g: g,
    b: b,
    rgb: rgb,
    hex: hex
  };
};
export var regexVars = {
  qrUnisotRegex: /^https:\/\/track.unisot.io\/(item|anchor)\/([a-z0-9]{0,64})$/,
  qrUuidUnisotRegex: /^https:\/\/track.unisot.io\/([a-z0-9-]{0,36})$/,
  emailRegex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  qrAbendumRegex: /^https:\/\/abendum-mvp.netlify.app\/auth\/login-page\?redirectPath=\/admin\/documents\/details\?data_id=([a-z0-9\-]{0,36})&sender=([a-zA-Z0-9]{0,34})&receiver=([a-zA-Z0-9]{0,34})&hash=([\w+=*]{0,44})$/
};
export var defaultGroupDisplayPicture = "https://testsdc.unisot.id/api/lookup/raw/75cd47dc-6c25-4e1f-9fe3-1ae1dc9c984e/iStock-164142821.jpg";
export var defaultGroupCoverPhoto = "https://testsdc.unisot.id/api/lookup/raw/eb07d0c7-6f49-492e-b95f-f06829dc18fe/iStock-1255481061.jpg";
export var defaultTwinCoverPhoto = "https://testsdc.unisot.id/api/lookup/raw/23a36015-cab7-42d3-b1fd-e39d427238e8/KTQ0AVR.jpg";
export var defaultTwinDisplayPicture = "https://testsdc.unisot.id/api/lookup/raw/41e3ea71-24c7-4e35-957b-6fed283a5c64/iS93rK7.jpg";