// Stats
var FETCH_STATISTICS = 'FETCH_STATISTICS';
var RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
var RECEIVE_STATISTICS_FAILED = 'RECEIVE_STATISTICS_FAILED';
var FETCH_EXTENDED_STATISTICS = 'FETCH_EXTENDED_STATISTICS';
var RECEIVE_ITEMS_EXTENDED_STATISTICS = 'RECEIVE_ITEMS_EXTENDED_STATISTICS';
var RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED = 'RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED';
var RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC = 'RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC';
var RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED = 'RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED';
var RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC = 'RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC';
var RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED = 'RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED';
var RECEIVE_BDI_EXTENDED_STATISTICS = 'RECEIVE_BDI_EXTENDED_STATISTICS';
var RECEIVE_BDI_EXTENDED_STATISTICS_FAILED = 'RECEIVE_BDI_EXTENDED_STATISTICS_FAILED';
var FETCH_EVENT_LOG = 'FETCH_EVENT_LOG';
var RECEIVE_EVENT_LOG = 'RECEIVE_EVENT_LOG';
var RECEIVE_EVENT_LOG_FAILED = 'RECEIVE_EVENT_LOG_FAILED';
var RESET_STATE = 'RESET_STATE';
export default {
  FETCH_STATISTICS: FETCH_STATISTICS,
  RECEIVE_STATISTICS: RECEIVE_STATISTICS,
  RECEIVE_STATISTICS_FAILED: RECEIVE_STATISTICS_FAILED,
  FETCH_EXTENDED_STATISTICS: FETCH_EXTENDED_STATISTICS,
  RECEIVE_ITEMS_EXTENDED_STATISTICS: RECEIVE_ITEMS_EXTENDED_STATISTICS,
  RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED: RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED,
  RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC: RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC,
  RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED: RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED,
  RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC: RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC,
  RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED: RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED,
  RECEIVE_BDI_EXTENDED_STATISTICS: RECEIVE_BDI_EXTENDED_STATISTICS,
  RECEIVE_BDI_EXTENDED_STATISTICS_FAILED: RECEIVE_BDI_EXTENDED_STATISTICS_FAILED,
  FETCH_EVENT_LOG: FETCH_EVENT_LOG,
  RECEIVE_EVENT_LOG: RECEIVE_EVENT_LOG,
  RECEIVE_EVENT_LOG_FAILED: RECEIVE_EVENT_LOG_FAILED,
  RESET_STATE: RESET_STATE
};