import types from "./types"; // Statistics

export var fetchStatistics = function fetchStatistics(params) {
  return {
    type: types.FETCH_STATISTICS,
    payload: params
  };
};
export var receiveStatistics = function receiveStatistics(json) {
  return {
    type: types.RECEIVE_STATISTICS,
    payload: json
  };
};
export var receiveStatisticsFailed = function receiveStatisticsFailed(error) {
  return {
    type: types.RECEIVE_STATISTICS_FAILED,
    payload: error
  };
};
export var fetchExtendedStatistics = function fetchExtendedStatistics(params) {
  return {
    type: types.FETCH_EXTENDED_STATISTICS,
    payload: params
  };
};
export var receiveExtendedStatistics = function receiveExtendedStatistics(json) {
  if (json.type === 'items') {
    return {
      type: types.RECEIVE_ITEMS_EXTENDED_STATISTICS,
      payload: json.result
    };
  } else if (json.type === 'eventsWeek') {
    return {
      type: types.RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC,
      payload: json.result
    };
  } else if (json.type === 'eventsMonth') {
    return {
      type: types.RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC,
      payload: json.result
    };
  } else if (json.type === 'messages') {
    return {
      type: types.RECEIVE_BDI_EXTENDED_STATISTICS,
      payload: json.result
    };
  }
};
export var receiveExtendedStatisticsFailed = function receiveExtendedStatisticsFailed(json) {
  if (json.type === 'items') {
    return {
      type: types.RECEIVE_ITEMS_EXTENDED_STATISTICS_FAILED,
      payload: json.error
    };
  } else if (json.type === 'eventsWeek') {
    return {
      type: types.RECEIVE_EVENTS_WEEK_EXTENDED_STATISTIC_FAILED,
      payload: json.error
    };
  } else if (json.type === 'eventsMonth') {
    return {
      type: types.RECEIVE_EVENTS_MONTH_EXTENDED_STATISTIC_FAILED,
      payload: json.error
    };
  } else if (json.type === 'messages') {
    return {
      type: types.RECEIVE_BDI_EXTENDED_STATISTICS_FAILED,
      payload: json.error
    };
  }
};
export var fetchEventLog = function fetchEventLog(params) {
  return {
    type: types.FETCH_EVENT_LOG,
    payload: params
  };
};
export var receiveEventLog = function receiveEventLog(json) {
  return {
    type: types.RECEIVE_EVENT_LOG,
    payload: json
  };
};
export var receiveEventLogFailed = function receiveEventLogFailed(error) {
  return {
    type: types.RECEIVE_EVENT_LOG_FAILED,
    payload: error
  };
};
export var resetState = function resetState() {
  return {
    type: types.RESET_STATE
  };
};