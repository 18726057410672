import _defineProperty from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/home/stephanie/Documents/Projects/frontend-monorepo/packages/unisot-web-viewer/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import itemDetailTypes from "../item-detail/types";
import types from "./types";
var INITIAL_STATE = {
  currentItem: {},
  loadingItem: false,
  errorSplitItem: {},
  splitItems: {},
  splitItemResponse: {},
  errorMergeItems: {},
  mergeItems: {},
  mergeItemsResponse: {},
  showDialog: false,
  loading: false
};

var itemMergeSplitReducer = function itemMergeSplitReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case itemDetailTypes.FETCH_ITEM_DETAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingItem: true
        });
      }

    case itemDetailTypes.RECEIVE_ITEM_DETAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentItem: action.payload,
          loadingItem: false
        });
      }

    case itemDetailTypes.RECEIVE_ITEM_DETAIL_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorSplitItem: action.error,
          loadingItem: false
        });
      }

    case types.CLEAR_ITEM_SPLIT_STATE:
      {
        return INITIAL_STATE;
      }

    case types.CREATE_SPLIT_ITEMS:
      {
        var numberOfItems = action.payload;
        var splitItemsObj = {};

        for (var i = 0; i < numberOfItems; i++) {
          splitItemsObj["item" + i] = state.currentItem.classification;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          splitItems: splitItemsObj
        });
      }

    case types.SAVE_ITEM_CHANGES:
      {
        var key = action.payload.key;
        var item = action.payload.item;
        return _objectSpread(_objectSpread({}, state), {}, {
          splitItems: _objectSpread(_objectSpread({}, state.splitItems), {}, _defineProperty({}, key, item))
        });
      }

    case types.SPLIT_ITEM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true,
          errorSplitItem: {}
        });
      }

    case types.RECEIVE_SPLIT_ITEM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          splitItemResponse: action.payload
        });
      }

    case types.RECEIVE_SPLIT_ITEM_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          errorSplitItem: action.error
        });
      }

    case types.MERGE_ITEMS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true,
          errorMergeItems: {}
        });
      }

    case types.RECEIVE_MERGE_ITEMS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          mergeItemsResponse: action.payload,
          loading: false
        });
      }

    case types.RECEIVE_MERGE_ITEMS_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          errorMergeItems: action.error,
          loading: false
        });
      }

    case types.SHOW_DIALOG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          showDialog: action.payload
        });
      }

    default:
      return state;
  }
};

export default itemMergeSplitReducer;